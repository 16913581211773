.special:first-child{
  animation: anim 4s infinite;
}

@keyframes anim {
  0%{
    margin-top: 0;
  }
  25%{
    margin-top: -4rem;
  }
  60%{
    margin-top: -8rem;
  }
  100%{
    margin-top: 0;
  }
}

@media only screen and (max-width:1280px) {
  @keyframes anim {
    0%{
      margin-top: 0;
    }
    25%{
      margin-top: -5rem;
    }
    60%{
      margin-top: -10rem;
    }
    100%{
      margin-top: 0;
    }
  }
}

@media only screen and (max-width:800px) {
  @keyframes anim {
    0%{
      margin-top: 0;
    }
    25%{
      margin-top: -2.5rem;
    }
    60%{
      margin-top: -5rem;
    }
    100%{
      margin-top: 0;
    }
  }
}