@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .border-gradient {
        border-image: linear-gradient(to right, #007bff, #213b98) 1;
        border-image-slice: 1;
    }
}

body::-webkit-scrollbar {
    width: 0.5em;
}

body::-webkit-scrollbar-track {
    background-color: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: none;
}

/* Hide scrollbar for Firefox */
body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    font-family: 'Open Sans', sans-serif;
}
.terms {
    padding: 1rem 2rem;
}
.terms h1 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 2rem;
}
.terms h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 2rem;
}
.terms h3 {
    font-size: 22px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 1rem;
}
.terms h4 {
    font-size: 22px;
    margin-bottom: 2.4rem;
    margin-bottom: 2rem;
}
.terms ul {
    list-style: none;
}
.terms li {
    font-size: 15px;
}
.terms p {
    font-size: 15px;
    margin-bottom: 2rem;
}

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
@keyframes smallSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.custom-animate-spin {
    animation: spin 1s linear infinite;
}

.customRotate {
    animation: smallSpin 1s linear infinite;
}
